:root {
   --primary-text: rgba(255,255,255,.85);
   --secondary-text: rgb(252, 252, 15);
}

.intro {
   display: inline-block;
   height: 100vh;
   width: 100vw;
   font-family: 'Poppins', sans-serif;
   overflow: hidden;
}

.hello h1 {
   font-size: 9em;
   font-weight: 1000;
   margin: 12% 10% 0 auto;
   overflow: hidden;    
   color: var(--primary-text);
   text-align: right;

}

.intro-content {
   text-align: left;
   color: var(--primary-text);
   z-index: 0;
   margin: 10% auto 5% 10%;
}

.intro-content h1 {
   color: var(--secondary-text);
   font-size: 4em;
   font-weight: bold;
   padding-bottom: 2%;
}

.intro-content h3 {
   font-size: 2.5em;
   font-weight: bold;
   line-height: 1.5em;
}

.skills {
   margin-left: 10%;
   border: 2px solid var(--primary-text);
   border-radius: 10px;
   width: 20%;
}

.typed-text {
   padding: 5%;
}

.typed-text span {
   color: var(--secondary-text);
   font-style: italic;
   font-weight: bold;
   font-size: 1.5rem;
   padding: 2%;
} 
/* 
@media only screen and (min-width: 354px) {
   .intro-content h4 {
      margin-left: 12%;
   }
   .skills {
      margin: 10% 0 0 auto;
      width: 85%;
   }
   .typed-text span {
      font-size: 1.3rem;
   }
}

@media only screen and (min-width: 377px) {
   .intro-content h4 {
      margin-left: 16%;
   }
}

@media only screen and (min-width: 402px) {
   .intro-content h4 {
      margin-left: 14%;
   }
   .skills {
      margin: 10% 0 0 auto;
      width: 85%;
   }
}

@media only screen and (min-width: 412px) {
   .intro-content h4 {
      margin-left: 18%;
   }
}

@media only screen and (min-width: 432px) {
   .intro-content h4 {
      margin-left: 20%;
   }
}

@media only screen and (min-width: 442px) {
   .intro-content h4 {
      margin-left: 25%;
   }
}

@media only screen and (min-width: 472px) {
   .intro-content h4 {
      margin-left: 30%;
   }
   .skills {
      width: 80%;
   } 
}

@media only screen and (min-width: 506px) {
   .intro-content h4 {
      margin-left: 10%;
   }
}

@media only screen and (min-width: 576px) {
   .intro {
      padding: 55% 8% 0 8%;
   }
   .intro-content h4 {
      margin-left: 14%;
   }
   .skills {
      width: 80%;
   } 
}

@media only screen and (min-width: 636px) {
   .intro-content h4 {
      margin-left: 25%;
   }
   .skills {
      width: 70%;
   } 
}

@media only screen and (min-width: 768px) {
   .intro {
      padding: 65% 8% 0 8%;
   }
   .hello {
      font-size: 3rem;
   }
   .intro-content h4 {
      margin-left: 25%;
      font-size: 1.5rem;
   }
   .skills {
      margin: 10% 0 0 auto;
      width: 60%;
   } 
}

@media only screen and (min-width: 1024px) {
   .intro {
      padding: 40% 8% 0 8%;
   }
   .hello {
      padding-bottom: 3%;
   }
   .intro-content h4 {
      margin-left: 30%;
      font-size: 1.5rem;
   }
   .skills {
      margin: 5% 0 0 auto;
      width: 50%;
   } 
   .typed-text span {
      font-size: 1.2rem;
   }
}

@media only screen and (min-width: 1366px) {
   .intro {
      padding: 32% 8% 0 8%;
   }
   .hello {
      font-size: 3.2rem;
   }
   .intro-content h4 {
      margin-left: 50%;
      font-size: 1.8rem;
   }
   .skills {
      margin: 8% 0 0 auto;
      width: 40%;
   } 
}
 */


.projects-container {
   display: block;
   align-items: center;
}

.project {
   padding-bottom: 10%;
   font-size: 1rem;
}

.project-title h5 {
   margin-top: 20px;
   color: rgb(255, 36, 131);
   font-size: 3rem;
}

.project-btn-links {
   margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
   .project {
      padding-bottom: 4%;
   }
   .project-title h5 {
      font-size: 2.5rem;
   }
}

@media only screen and (min-width: 1366px) {
   .project-title h5 {
      font-size: 2.5rem;
   }
}

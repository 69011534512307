* {
margin: 0;
padding: 0;
font-family: 'Poppins', sans-serif;
font-size: 16px;
}

.main-container {
background-color: rgb(17, 25, 28);
width: 100vw;
overflow: hidden;
}

.nav-container {
width: 100vw;
margin: 0;
}

.intro-container { 
text-align: right;
}

#intro {
   background-image: url('./img/black-rocks-lg.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-attachment: fixed;
}

.abt-container {
padding: 10% 0;
}

.projects-container {
padding-top: 10%;
}

.footer-container {
padding: 2% 0 2% 0;
background-color: rgba(0, 0, 0, 0)
}

.tsparticles-canvas-el {
position: absolute;
width: 100%;
height: 100%;
} 

.space-container {
padding: 1%;
height: 1vh;
}

@media screen and (min-width: 576px) {
   .abt-container {
      padding: 8% 0;
   }
}

@media screen and (min-width: 768px) {
   .abt-container {
      padding-top: 5%;
   }
   .projects-container {
      padding-top: 5%;
   }
}

@media screen and (min-width: 1024px) {

}

@media screen and (min-width: 1366px) {

}




.card-img-top {
   align-items: center;
   width: 60%;
   margin: 0 10% 0 10%;
   border: .5px solid rgba(78, 80, 78, 0.79);
   border-radius: 20px;
   padding: 1px;
   background-color: rgb(255, 255, 255, .85);
}

.card-body {
   color: rgba(255,255,255,.85);
   padding-bottom: 0;
}

.card-title p {
   font-size: 1.5rem;
}

.card-desc p {
   font-size: 1rem;
   margin: 2% 10% 0 10%;
}

.card:hover {
   box-shadow: 5px 8px 10px 2px rgb(27, 57, 70) !important;
}

.project-btn-links {
   font-size: .5rem;
   color: rgba(255,255,255,.70);
}

.divider {
   color: whitesmoke;
   margin: 0 10% 0 10%;
   font-size: 5.5rem;
}

@media screen and (min-width: 576px) {
   .card-title p {
      font-size: 1.2rem;
   }
   .card-desc p {
      font-size: .9rem;
      padding-bottom: 2%;
   }
}

@media only screen and (min-width: 768px) {
   .card-title p {
      font-size: 1.3rem;
   }
   .card-desc p {
      font-size: 1rem;
   }
}

@media only screen and (min-width: 1024px) {
   .card-title p {
      font-size: 1.3rem;
   }
   .card-desc p {
      font-size: .9rem;
   }
}

@media only screen and (min-width: 1366px) {
   .card-img-top {
      width: 40%;
   }
   .card-desc p {
      margin: 2% 15% 0 15%;
   }
}

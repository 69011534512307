/* NEED to 
   understand why I needed to use !IMPORTANT and remove them */

:root {
   --background: rgb(17, 25, 28);
   --neon-light-pink: rgb(255, 230, 255);
   --neon-med-pink: rgb(255, 101, 189);
   --neon-dark-pink: rgb(255, 36, 131);
   --nav-pink: rgb(255, 36, 131);
   --nav-links-toggler: rgba(255, 255, 255, 0.85);
}

/*
*
* =====================
* GENERAL
* =====================
*
*/

.navbar {
   width: 100vw;
   //background: var(--background);
}

.navbar-nav {
   width: 100vw;
}

#navbar-dropdown {
   text-align: right;
}

.neon-text span {
   font-family: 'Poppins', sans-serif;  
   font-size: 2.2em;
   margin-right: .3em;
}

.link-text {
   color: white;
   font-size: 1.3em;
   padding: 1em;
}

.all-links {
   padding-left: 35%;
}

.nav-links {
   display: inline-block;
   font-family: 'Poppins', sans-serif;
}

.nav-links:hover {
   color: var(--nav-pink);
}

.navbar-toggler {
   color: var(--nav-links-toggler);
   margin: 1% 5% 2% 0;
   padding: 0;
}
/* 
@media only screen and (min-width: 576px) {
   a.neon-text {
      margin-right: 1rem;
   }
   .neon-text span {
      font-size: 1.4rem;
      margin-right: .4rem;
   }
   .nav-links {
      font-size: .8em;
      display: inline-block;
      
   }
   .all-links {
      padding-left: 10px;
   }
} */
/* 
@media only screen and (min-width: 768px) {
   .neon-text span {
      font-size: 1.6rem;
      margin-right: .6rem;
   }
   a.neon-text {
      margin-right: 7%;
   }
   .nav-links {
      font-size: 1rem;
      margin-right: .5em;
   }
}

@media screen and (min-width: 1024px) {
   .neon-text span {
      font-size: 2.2rem;
      margin-right: .8rem;
   }
   a.neon-text {
      margin-right: 15%;
   }
   .all-links {
      margin-right: 1.8rem;
   }
}

@media screen and (min-width: 1366px) {
   .nav-links {
      margin-right: 2rem;
      font-size: 1.2rem;
   }
} */

/*
*
* =====================
* NEON NAME
* =====================
*
*/

.neon-text {
   margin-left: 8%;
}

.g {
   padding-left: 0.5em;
}

@keyframes blink {
   0%,
   22%,
   30%,
   75% {
      color: var(--neon-light-pink);
      text-shadow: 0 0 0.6rem var(--neon-light-pink), 0 0 1.5rem var(--neon-med-pink),
      -0.2rem 0.1rem 1rem var(--neon-med-pink), 0.2rem 0.1rem 1rem var(--neon-med-pink),
      0 -0.5rem 2rem var(--neon-dark-pink), 0 0.5rem 3rem var(--neon-dark-pink);
   }
   28%,
   33% {
      color: var(--neon-med-pink);
      text-shadow: none;
   }
   82%,
   97% {
      color: var(--neon-dark-pink);
      text-shadow: none;
   }
}

.flicker {
   animation: shine 2s forwards, blink 10s 2s infinite;
}

.fast-flicker {
   animation: shine 2s forwards, blink 8s 1s infinite;
}

@keyframes shine {
   0% {
      color: #6b1839;
      text-shadow: none;
   }
   100% {
      color: var(--neon-light-pink);
      text-shadow: 0 0 0.6rem var(--neon-light-pink), 0 0 1.5rem var(--neon-med-pink),
      -0.2rem 0.1rem 1rem var(--neon-med-pink), 0.2rem 0.1rem 1rem var(--neon-med-pink),
      0 -0.5rem 2rem var(--neon-dark-pink), 0 0.5rem 3rem var(--neon-dark-pink);
   }
}

@keyframes flicker {
   from {
      opacity: 1;
   }
   4% {
      opacity: 0.9;
   }
   6% {
      opacity: 0.85;
   }
   8% {
      opacity: 0.95;
   }
   10% {
      opacity: 0.9;
   }
   11% {
      opacity: 0.922;
   }
   12% {
      opacity: 0.9;
   }
   14% {
      opacity: 0.95;
   }
   16% {
      opacity: 0.98;
   }
   17% {
      opacity: 0.9;
   }
   19% {
      opacity: 0.93;
   }
   20% {
      opacity: 0.99;
   }
   24% {
      opacity: 1;
   }
   26% {
      opacity: 0.94;
   }
   28% {
      opacity: 0.98;
   }
   37% {
      opacity: 0.93;
   }
   38% {
      opacity: 0.5;
   }
   39% {
      opacity: 0.96;
   }
   42% {
      opacity: 1;
   }
   44% {
      opacity: 0.97;
   }
   46% {
      opacity: 0.94;
   }
   56% {
      opacity: 0.9;
   }
   58% {
      opacity: 0.9;
   }
   60% {
      opacity: 0.99;
   }
   68% {
      opacity: 1;
   }
   70% {
      opacity: 0.9;
   }
   72% {
      opacity: 0.95;
   }
   93% {
      opacity: 0.93;
   }
   95% {
      opacity: 0.95;
   }
   97% {
      opacity: 0.93;
   }
   to {
      opacity: 1;
   }
}
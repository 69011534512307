.buttons {
    padding-bottom: 0;
}

.btn-link {
    border: 1px solid rgba(255,255,255,.70);
    border-radius: 10px;
    font-size: .8rem;
    padding: 10px;
    color: rgba(255,255,255,.85);
    background-color: black;
    box-shadow: 0 0 10px rgba(255,255,255,.70);    justify-content: center;
    align-items: center;
    text-align: center;
}


